import { TranslateService } from '@ngx-translate/core';
import { AppConfig, Endpoints } from '../models/app/app-config';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';

export abstract class BaseProvider {
    private endPoints: Endpoints;
    appConfig: AppConfig;
    protected readonly GENERIC_ERROR = this.translateSrv.instant('ERROR.GENERIC.API_MESSAGE');
    protected constructor(
        protected configSrv: PrivateConfigurationService,
        protected translateSrv: TranslateService,
    ) {
        this.appConfig = this.configSrv.config;
        this.endPoints = this.configSrv.config.endpoints;
    }

    getApexApiUrl(api: ApexApi): string {
        return `${this.endPoints.apex.baseUrl}/${this.endPoints.apex.apis[api]}`;
    }

    getApiMngApiUrl(api: ApiMngApi): string {
        return `${this.endPoints.apiMgt.baseUrl}/${this.endPoints.apiMgt.apis[api]}`;
    }

    getApttusApiUrl(api: ApttusApi): string {
        return `${this.endPoints.apttus.baseUrl}/${this.endPoints.apttus.apis[api]}`;
    }
}

export enum ApexApi {
    PaperCheck = 'paperCheck',
    CreateQuoteEntities = 'createQuoteEntities',
    DoApttusAction = 'doApttusAction',
    ManageQuoteList = 'manageQuoteList',
    ActionsFilters = 'getActionsFilters',
    CheckItems = 'checkItems',
    ScoreCard = 'scoreCard',
    ProductsDates = 'productsDates',
    GetSalesUpState = 'getSalesUpState',
    SaveSalesUpState = 'saveSalesUpState',
    MonitorQuote = 'monitorQuote',
    MonitorOrder = 'monitorOrder',
    CallToSaleabilityMatrix = 'callToSaleabilityMatrix',
    MailBlacklist = 'mailBlacklist',
    Delete = 'deleteBOReason',
    ChangeCartOwner = 'changeCartOwner',
    CheckCartDual = 'checkCartDual',
    ProductOptsMatrix = 'productOptsMatrix',
    GetModifiedAttributes = 'getModifiedAttributes',
    GetScontiList = 'getScontiList',
    CommodityForCombinedSale = 'commodityForCombinedSale',
    UpdateAtecoCode = 'updateAtecoCode',
    CheckRataFissa = 'checkRataFissa',
    CheckAsset = 'checkAsset',
    GetCombinedSales = 'getCombinedSales',
    CreateCartFromCart = 'CreateCartFromCart',
    QuoteDetail = 'detailQuote',
    PaymentDetail = 'paymentDetail',
    QuoteLineItemDetail = 'detailQuoteLineItem',
    OrderLineItemDetail = 'detailOrderLineItem',
    OrderDetail = 'detailOrder',
    DeleteOrder = 'deleteOrder',
    AmendOrder = 'amendOrder',
    DeleteDetailOrder = 'deleteDetailOrder',
    Rework = 'rework',
    PaymentOrder = 'paymentOrder',
    OrderActions = 'orderActions',
    ResubmitOrder = 'resubmitOrder',
    EligibilityCheck = 'eligibilityCheck',
    GetMassiveList = 'getMassiveList',
    GetConventionList = 'getConventionList',
    ApplyConvention = 'applyConvention',
    GetQuoteOrderHistory = 'getQuoteOrderHistory',
    LearningDoiSign = 'learningDoiSign',
    LearningJumpToOrder = 'learningJumpToOrder',
    GetAssetReportAssetView = 'assetReportAssetView',
    GetAssetReportOliView = 'assetReportOliView',
    GetAssetReportOtiView = 'assetReportOtiView',
    CheckDefaultPotentialForGasActivation = 'checkDefaultPotentialForGasActivation',
    Autofill = 'autofill',
}

export enum ApiMngApi {
    GetAssetDetails = 'getAssetDetails',
    GetProductListById = 'getProductListById',
    GetProductDetails = 'getProductDetails',
    CpChangeCommerciale = 'cpChangeCommerciale',
    CheckIban = 'checkIban',
    GenerateDocOT = 'generateDocOT',
    GenerateScriptOT = 'generateScriptOT',
    DownloadMonitorCSV = 'downloadMonitorCSV',
    CheckPdf = 'checkPdf',
    StubGenerateDocOT = 'stubGenerateDocOT',
    StubGenerateScriptOT = 'stubGenerateScriptOT',
    OldCreditCheck = 'oldCreditCheck',
    UploadZipRecordings = 'uploadZipRecordings',
    RetrieveAttachmentList = 'retrieveAttachmentList',
    RetrieveAttachment = 'retrieveAttachment',
    ReplaceAttachmentBO = 'replaceAttachmentBO',
    RetrieveRegistrationData = 'retrieveRegistrationData',
    RetrieveDataSalesUp = 'retrieveDataSalesUp', // old
    RetrieveCustomerData = 'retrieveCustomerData',
    RetrieveDataCreditCheck = 'retrieveDataCreditCheck',
    RetrieveInsoluto = 'retrieveInsoluto',
    ComuneAffinityByIstat = 'comuneAffinityByIstat',
    RecuperaDocumentazione = 'recuperaDocumentazione',
    SfaOfferingRetrieve = 'sfaOfferingRetrieve',
    OcrInvia = 'ocrInvia',
    OcrFeedback = 'ocrFeedback',
    UploadIdentityCard = 'uploadIdentityCard',
    CheckPDR = 'checkPDR',
    CheckPOD = 'checkPOD',
    UploadMp3VocalOrder = 'uploadMp3VocalOrder',
    UploadPlicoSigned = 'uploadPlicoSigned',
    UploadAttachments = 'uploadAttachments',
    DeleteDocumentazione = 'deleteDocumentazione',
    UploadSelfCertification = 'uploadSelfCertification',
    CalculateFastwebCluster = 'calculateFastwebCluster',
    CreateSuspendOrder = 'createSuspendOrder',
    TerminationCostsRetrieve = 'terminationCostsRetrieve',
    CreateInterruptionOrder = 'createInterruptionOrder',
    CancelOrAmend = 'cancelOrAmend',
    GetPaymentTools = 'getPaymentTools',
    CheckPDRActivation = 'checkPDRActivation',
    CheckPODActivation = 'checkPODActivation',
    CreateResumeOrder = 'createResumeOrder',
    StandaloneDiscount = 'standalonediscount',
    UploadBulkOrder = 'uploadBulkOrder',
    CheckCoupon = 'checkCoupon',
    CreateQuoteFromCart = 'createCartFromCart',
    CancelOrder = 'cancelOrder',
    GetAssetSummaryData = 'getAssetSummaryData',
    GetAssetTechnicalData = 'getAssetTechnicalData',
    GetAssetProcessData = 'getAssetProcessData',
    GetAssetPaymentData = 'getAssetPaymentData',
    DownloadBulkOrder = 'downloadBulkOrder',
    GetCsvSalesProcess = 'getCsvSalesProcess',
    CreateTerminateOrder = 'createTerminateOrder',
    GetOnConditionRecords = 'getOnConditionRecords',
    CopyOnConditionRecords = 'copyOnConditionRecords',
    TransferSelfReading = 'transferSelfReading',
    GetInfoDistributore = 'getinfodistributore',
    WorkOrderSubmission = 'workOrderSubmission',
    RetrieveQuotationCosts = 'retrieveQuotationCosts',
    RetrieveQuotationCostsApim = 'retrieveQuotationCostsApim',
    Revocation = 'revocation',
    GetDdlData = 'getDdlData',
    SendSupplyAddress = 'sendSupplyAddress',
    GetAggregationAccounts = 'getAggregationAccounts',
    GetAggregationEffectiveDate = 'getAggregationEffectiveDate',
    CreateAccountUnion = 'createAccountUnion',
    CreateAccountSplit = 'createAccountSplit',
    SuggestCity = 'suggestCity',
    SuggestStreet = 'suggestStreet',
    SuggestNumber = 'suggestNumber',
    Normalize = 'normalize',
    SearchByZip = 'searchByZip',
    SearchByCity = 'searchByCity',
    SearchByStreetNumber = 'searchByStreetNumber',
    CheckCallToCheckDOI = 'checkCallToCheckDOI',
    CancelApttusQuote = 'cancelApttusQuote',
    RejectApttusQuote = 'rejectApttusQuote',
    RetrieveArrearsByAccount = 'retrieveArrearsByAccount',
    ApttusQuery = 'apttusQuery',
    GetEffectiveDate = 'getEffectiveDate',
    ChangeEffectiveDate = 'changeEffectiveDate',
    TerminateInsurance = 'terminateInsurance',
    CheckInsurancePODPDR = 'checkInsurancePODPDR',
    CheckInsuranceTaxCode = 'checkInsuranceTaxCode',
    GestioneTeleletto = 'gestioneTeleletto',
    GetAzureBulkMainList = 'getAzureBulkMainList',
    GetAzureBulkDetails = 'getAzureBulkDetails',
    AddNewCreditCard = 'addNewCreditCard',
    CheckBalance = 'checkBalance',
    GetUnusedCdc = 'getUnusedCdc',
    CeaseExpiredCdc = 'ceaseExpiredCdc',
    UpsertCase = 'upsertCase',
    RetrieveCase = 'retrieveCase',
    CheckUniqueRegistrationEmail = 'checkUniqueRegistrationEmail',
    CustomerEngagement = 'customerEngagement',
    GetEse = 'getEse',
    PutBlob = 'putBlob',
    UpsertEmail = 'upsertEmail',
    LeggiPropostaRateizzazione = 'leggiPropostaRateizzazione',
    ElencaFattureRateizzabili = 'elencaFattureRateizzabili',
    AttivaPropostaRateizzazione = 'attivaPropostaRateizzazione',
    UpsertTask = 'upsertTask',
    CloseTask = 'closeTask',
    CheckQuoteCorrelation = 'checkQuoteCorrelation',
    ElencoFattureV2 = 'elencoFattureV2',
    CheckPriceListAmm = 'checkPriceListAmm',
    DiscountDetails = 'discountDetails',
    RecuperoMatricolaStrumento = 'recuperoMatricolaStrumento',
}

export enum ApttusApi {
    CreateProposal = 'createProposal',
    Update = 'update',
    Query = 'query',
    Generic = 'generic',
    CheckCap = 'checkCap',
    QueryData = 'queryData',
}
